.element {
  position: relative;

  &:focus {
    outline: none;
  }
}

.selected {
  border: 3px dashed lightgreen;
}
