@import '~src/scss/variables';

.resourceItem {
  display: flex;
  height: inherit;
  margin-right: $spacer/2;
}

.resourceImage {
  max-height: calc(100% - 2 * #{$spacer});
}

.resourceItemTools {
  padding: 0 4px;
}
