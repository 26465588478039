@import '~src/scss/variables';

.elementForm {
  margin-bottom: $spacer;
}

.elementFormBody {
  &.closed {
    display: none;
  }
}

.img {
  max-width: 40px;
}

.title {
  position: relative;
  left: -6px;
  svg {
    position: relative;
    top: -3px;
  }
}
