@import '~src/scss/variables';

.backdrop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize($gray-600, 0.4);
}

.modalContainer {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: $white;
  border: 1px solid $border-color;
  box-shadow: 0px 0px 20px $gray-700;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  padding: $spacer/2 $spacer;
  border-bottom: 1px solid $border-color;
}

.modalBody {
  min-width: 400px;
  padding: $spacer;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding: $spacer/2 $spacer;
  border-top: 1px solid $border-color;
}
