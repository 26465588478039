@import '~src/scss/variables';

.inputField {
  margin-bottom: $spacer;
}

.inline {
  display: flex;

  label {
    white-space: nowrap;
    margin-right: $spacer;
  }

  input {
    width: 100%;
  }
}
