$background-color: hsla(192, 43, 68, 0.5);
$border-color: darken($background-color, 20%);

.cell {
  position: relative; // Open a new stacking context
  border: 1px solid $background-color;
  transition: all 0.3s;

  &.isDragOver {
    $is-drag-over-background-color: darken($background-color, 60%);
    background-color: $is-drag-over-background-color;
    border-color: darken($is-drag-over-background-color, 20%);
  }

  &.isGuidesFront {
    z-index: 1;
  }
}
