@import '~src/scss/variables';

$menu-height: 50px;
$stylespanel-width: 200px;
$resourcespanel-height: 200px;

.app {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.menu {
  position: absolute;
  width: 100%;
  height: $menu-height;
  border-bottom: 1px solid $border-color;
  box-shadow: 0px 0px 2px $border-color;
}

.workingbench {
  position: absolute;
  top: $menu-height;
  left: 0;
  width: calc(100% - #{$stylespanel-width});
  height: calc(100% - #{$menu-height} - #{$resourcespanel-height});
  padding: 16px;
  overflow: scroll;
}

.stylesPanel {
  position: absolute;
  top: $menu-height;
  right: 0;
  width: $stylespanel-width;
  height: calc(100% - #{$menu-height} - #{$resourcespanel-height});
  border-left: 1px solid $border-color;
}

.resourcesPanel {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: $resourcespanel-height;
  border-top: 1px solid $border-color;
  box-shadow: 0px 0px 2px $border-color;
}
