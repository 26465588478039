@import '~src/scss/variables';

$button-color: $white;
$border-color: $gray-300;

.button {
  border: 1px solid $border-color;
  margin: 0;
  padding: $spacer/2 $spacer;
  width: auto;
  overflow: visible;

  background: $button-color;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  transition: 0.3s background-color;

  &:disabled {
    svg {
      fill-opacity: 0.4;
    }
  }

  svg {
    vertical-align: top;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $gray-200;
  }

  &:active,
  &:focus {
    outline: none;
    background-color: $gray-500;
    box-shadow: 2px 2px 4px $gray-200;
  }

  &.fullWidth {
    display: block;
    width: 100%;
  }

  &.icon {
    padding: 0;
    border: none;

    & > svg {
      display: block;
    }
  }

  &.variant-danger {
    color: $white;
    border: none;
    background-color: $danger;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($danger, 10%);
    }
  }

  &.variant-text {
    padding: 0;
    display: inline;
    background: none;
    border: none;
    color: inherit;
    -webkit-appearance: none;

    &:hover {
      color: teal;
    }

    &:active,
    &:focus {
      box-shadow: none;
    }
  }
}
